import React from "react";
import logo from "../assets/logo.png";
// import { BsFacebook, BsTwitter, BsInstagram } from "react-icons/bs";
import Instagram from "../assets/instagram-white-icon.svg"
// import docs1 from "../assets/docs/"
import Facebook from "../assets/facebook-app-round-white-icon.svg"
import { FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";
export default function Footer() {
  const links = [
    {
      title: "NFT",
      data: ["OpenSea", "Maker", "Learn"],
    },
  ];
  const socialLink = [
    <a href="https://www.facebook.com/CryptVideos"><img src={Facebook} alt="" width="20px" /></a>,
    <a href="https://www.instagram.com/cryptvideos/"><img src={Instagram} alt="" width="20px" /></a>
  ];
  return (
    <footer>
      <div className="upper">
        <div className="brand-container">
          <div className="brand">
            <img src={logo} alt="logo" />
          </div>
          <p> MAKE YOUR CONTENT UNIQUE</p>
          <ul>
            {socialLink.map((link, index) => (
              <li key={index}>{link}</li>
            ))}
          </ul>
        </div>
        <div className="links">
          {links.map(({ title, data }, index) => {
            return (
              <div className="link" key={index}>
                <h4>{title}</h4>
                <ul>
                  {data.map((link, index2) => (
                    <li key={index2}>{link}</li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Link
            to="/terms"
            className="mylink"
            style={{
              textDecoration: "none",
              color: "white",
              margin: "5px",
            }}
          >
            Terms And Conditions
          </Link>
          <Link
            to="/privacy"
            className="mylink"
            style={{
              textDecoration: "none",
              color: "white",
              margin: "5px",
            }}
          >
            Privacy Policy
          </Link>
          <Link
            to="/legal-disclaimer"
            className="mylink"
            style={{
              textDecoration: "none",
              color: "white",
              margin: "5px",
            }}
          >
            Legal Disclaimer
          </Link>
          {/* <a href="../assets/docs/ADA.pdf" className="mylink" style={{
            textDecoration: "none",
            color: "white",
            margin: "5px",
          }}> Americans with Disabilities Act (ADA) Accessibility Policy
            For CryptVideos</a> */}
            {/* <embed src="../assets/docs/ADA.pdf" width="100%" height="600px" /> */}

          <Link
            to="/americanswithDisabilitiesact"
            className="mylink"
            style={{
              textDecoration: "none",
              color: "white",
              margin: "5px",
            }}
          >
            Americans with Disabilities Act (ADA) Accessibility Policy
            For CryptVideos
          </Link>
          <Link
            to="/gdpr-policy"
            className="mylink"
            style={{
              textDecoration: "none",
              color: "white",
              margin: "5px",
            }}
          >
            GDPR Policy For CryptVideos
          </Link>
          <Link
            to="/cookie-policy"
            className="mylink"
            style={{
              textDecoration: "none",
              color: "white",
              margin: "5px",
            }}
          >
            Cookie Policy for CryptVideos
          </Link>
       
          {/* <a href="../assets/docs/GDPR-Policy.pdf" className="mylink" style={{
            textDecoration: "none",
            color: "white",
            margin: "5px",
          }}>  GDPR Policy For CryptVideos</a> */}
     
          {/* <a href="../assets/docs/Cookie-Policy.pdf" className="mylink" style={{
            textDecoration: "none",
            color: "white",
            margin: "5px",
          }}>              
          </a> */}
        </div>
      </div>
      <div className="lower">
        {/* <span> Copyright 2024</span> */}
        <span>
          <a
            href="mailto:CryptVideosllc@gmail.com"
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            Email: CryptVideosllc@gmail.com
          </a>
        </span>
        <div className="">
          <span
            style={{
              color: "white",
            }}
          >
            Location
          </span>{" "}
          <p>30 N Gould St Ste R</p>
          <p>Sheridan, WY 82801</p>
          <p>USA</p>
        </div>
        <Link
          to="/Copyright"
          style={{
            textDecoration: "none",
            color: "white",
          }}
        >
          &copy; Copyright 2024 CryptoVideos{" "}
        </Link>
      </div>
    </footer>
  );
}
