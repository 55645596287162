import pdf from "../assets/docs/GDPR-Policy.pdf"

const PDFViewer = () => {
 return (
  <div>
    <iframe src={pdf} width="100%" height="1200px" />
  </div>
 ); 

}

export default PDFViewer;
